<template>
  <div id="Car">
    <div class="card-header bg-brand px-3 py-4 rounded-t-lg">
      <p>
        <span class="brand-color-dark">Fahrzeuge</span><br />
        Deine persönlichen Fahrzeuge für die Mitfahrzentrale.
      </p>
    </div>
    <div class="card-content space-y-3">
      <CarListItem image="https://images.pexels.com/photos/1545743/pexels-photo-1545743.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" model="Model" brand="Brand" seats=3 licensePlate="LEV AB 123" color="red" ></CarListItem>
      <hr class="space">
      <CarListItem image="https://images.pexels.com/photos/1545743/pexels-photo-1545743.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" model="Model" brand="Brand" seats=3 licensePlate="LEV AB 123" color="blue" ></CarListItem>
      <hr class="space">
      <CarListItem image="https://images.pexels.com/photos/1545743/pexels-photo-1545743.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" model="Model" brand="Brand" seats=3 licensePlate="LEV AB 123" color="white" ></CarListItem>
      <hr class="space">
      <CarListItem image="https://images.pexels.com/photos/1545743/pexels-photo-1545743.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" model="Model" brand="Brand" seats=3 licensePlate="LEV AB 123" color="black" ></CarListItem>
      <hr class="space">
      <CarListItem image="https://images.pexels.com/photos/1545743/pexels-photo-1545743.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" model="Model" brand="Brand" seats=3 licensePlate="LEV AB 123" color="default" ></CarListItem>
    </div>
  </div>
</template>


<script>
import CarListItem from "@/components/CarListItem";

export default {
  name: "Car",
  components: {
    CarListItem
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
  },
  computed: {
  },
};
</script>