<template>
    <div class="CarListItem grid grid-cols-2">
        <div class="middle">
            <div class="colorTag" v-bind:class="classObject()"></div>
            <p class="headline">{{brand}} - {{model}}</p>
            <p class="licensePlate">{{licensePlate}}</p>
            <p class="seats input-helper-text"><font-awesome-icon icon="user" class="icon text-danger"/> {{seats}} Sitzplätze</p>
        </div>
        <div class="right my-auto justify-self-end">
            <button class=""><font-awesome-icon icon="trash" class="icon text-danger"/></button>
        </div>
    </div>
</template>

<script>
export default {
  name: "CarListItem",
  props: {
    image: String,
    model: String,
    brand: String,
    seats: String,
    color: String,
    licensePlate: String,
    canDelete: Boolean,
  },
  methods: {
    classObject: function () {
      return {
        "bg-red": this.color == "red",
        "bg-blue": this.color == "blue",
        "bg-white": this.color == "white",
        "bg-black": this.color == "black",
      };
    }
  }
};
</script>
